import React, {PureComponent, ReactNode} from "react";

export type BookingAdvantagesProps = {
    headline: string,
};

export class BookingAdvantages extends PureComponent<BookingAdvantagesProps> {

    render(): ReactNode {
        const {
            headline,
        } = this.props

        return (
            <div className={`bookingAdvantages px-6 md:px-12 pb-12 md:pb-24 grid grid-cols-12 md:gap-x-12`}>
                <h2 className={`text-2xl leading-8 font-semibold md:text-3xl md:leading-9 font-serif text-gray-900 col-span-full pb-6`}>{headline}</h2>

                <div className={'col-span-12 lg:col-span-4 '}>
                    <i className={'fa-kit fa-clock-rotate-left text-primary text-3xl block mb-3'}/>
                    <h3 className={'pb-2 text-lg leading-8 font-semibold font-serif text-black'}>Buche 24/7</h3>
                    <p className={'text-base leading-6 font-normal text-gray-500 pb-8'}>Alle freien Werkstattermine einfach von überall aus buchen.</p>
                </div>

                <div className={'col-span-12 lg:col-span-4 '}>
                    <i className={'fa-kit fa-receipt text-primary text-3xl block mb-3'}/>
                    <h3 className={'pb-2 text-lg leading-8 font-semibold font-serif text-black'}>Transparente Preiskalkulation</h3>
                    <p className={'text-base leading-6 font-normal text-gray-500 pb-8'}>Wir berechnen dir die vorraussichtlich anfallenden Kosten pro Werkstatt. Alles nach Herstellervorgaben.</p>
                </div>

                <div className={'col-span-12 lg:col-span-4 '}>
                    <i className={'fa-kit fa-badge-check text-primary text-3xl block mb-3'}/>
                    <h3 className={'pb-2 text-lg leading-8 font-semibold font-serif text-black'}>Top Werkstätten</h3>
                    <p className={'text-base leading-6 font-normal text-gray-500 pb-8'}>Tausende gut bewertete Autohäuser und Werkstätten in ganz Deutschland direkt online buchen.</p>
                </div>

            </div>
        );
    }
}